<template>
  <div>
    <KTCodePreview v-bind:title="'Cấu hình cài đặt của nhà phát triển'">
      <template v-slot:preview>
        <b-table :items="configs"></b-table>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

export default {
  components: {
    KTCodePreview,
  },
  data() {
    return {
      configs: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Development', route: 'development-tools' },
      { title: 'List setting' },
    ]);
  },
  beforeMount() {
    const svr = ApiService.getServerInformation();
    if (svr) {
      this.configs.push({
        key: 'Read Server',
        value: svr.read,
        description: 'Server dùng để đọc dữ liệu',
      });

      this.configs.push({
        key: 'Write Server',
        value: svr.write,
        description: 'Server dùng để ghi dữ liệu',
      });

      this.configs.push({
        key: 'Phiên bản của server',
        value: svr.version,
        description: 'Phiên bản build của server backend.',
      });
    }
  },
};
</script>
